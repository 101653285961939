import weixinJsSdk from "weixin-js-sdk";
import { getShare } from '@/server';
import { Toast } from 'vant';
import { getOpenId } from '@/server';
import store from '@/store';
import router from '@/router';/**
 * Vue的插件，用来获取和设置localStorage存储
 **/
let local = {
    save(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    fetch(key) {
        return JSON.parse(localStorage.getItem(key)) || {}
    }
}

export default {
    install: function (vm) {
        vm.prototype.$local = local
    }
}

export function isLogin() {
    return true;
    // localStorage.bindStatus!=='1'
    // if (localStorage.bindStatus!=='1') {
    //     return false;
    // } else {
    //     return true;
    // }
    // if (!localStorage.token || localStorage.token == "") {
    //     return false;
    // } else {
    //     return true;
    // }
}

export function nfmoney(s, power, n, type) {
    /**转换金额
     * s:金额（单位：默认为分）
     * power:10的几次冥次方（默认为-2）
     * n:保留几位小数（默认为2）
     * type:s的类型（默认为金额）'':为金额，'z'为非金额,或非金额转换的数值,则power和n必传
     */
    var fuzhi = false;
    if (s === '' || s === undefined || s === null) {
        return '暂无数据';
    }
    if (s < 0) {
        fuzhi = true;
        s = Math.abs(s);
    }
    power = (type !== 'z') ? (power || -2) : power;
    s = (parseFloat(s) * Math.pow(10, power));
    n = n >= 0 && n <= 20 ? n : 2;
    s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
    var l = s.split('.')[0].split('').reverse(),
        r = (type === 'z' && n === 0) ? '' : (n === 0 ? '' : s.split('.')[1]);
    var t = '';
    for (var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 === 0 && (i + 1) != l.length ? ',' : '');
    }
    return (fuzhi ? '-' : '') + t.split('').reverse().join('') + (r !== '' ? ('.' + r) : '');
}

//设置cookie
export function setCookie(c_name, value, expiredays) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =c_name +"=" +escape(value) +(expiredays == null ? "" : ";expires=" + exdate.toGMTString());
  }

  //取回cookie
  export function  getCookie(c_name) {
    let c_start='';
    let c_end='';
    if (document.cookie.length > 0) {
      c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  }

  export function delCookie(c_name)
  {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval=getCookie(c_name);
  if(cval!=null)
  document.cookie= c_name + "="+cval+";expires="+exp.toGMTString();
}
  
export function getUrlParam(key) {
  var url = window.location.href;
  var urlParam = '';
  if (url.indexOf('?') > 0) {
      urlParam = url.substr(url.indexOf('?') + 1);
  }
  if (urlParam.indexOf('#') > 0) {
      urlParam = urlParam.substr(0, urlParam.indexOf('#'));
  }
  var urlParams = urlParam.split('&');
  var param = {};
  if (!urlParams || urlParams.length <= 0) {
      return param;
  }
  for (var i = 0; i < urlParams.length; i++) {
      var str = urlParams[i];
      var p = str.split('=');
      if (p.length == 2) {
          var name = decodeURIComponent(p[0]);
          var value = decodeURIComponent(p[1]);
          param[name] = value;
      }
  }
  if (key && param[key] !== null && param[key] !== 'undefined') {
      return param[key];
  }

  return param;
}
export const domainUrl = window.g.domainUrl
export function hidewxOption(){
	// document.addEventListener('WeixinJSBridgeReady',()=> {
	// 	WeixinJSBridge.call('hideOptionMenu');
	// }, false); 
	weixinJsSdk.ready(()=>{
		weixinJsSdk.hideOptionMenu()
	})
}
export function getShareData(url){
  const params = {
    url: url
  }
  getShare(params).then(res=>{
    const { status, data, msg } = res;
    if (status == 200) {
      // this.wxShare(data.timestamp,data.nonceStr,data.signature)
      //微信二次分享
      weixinJsSdk.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wxe40be82e1238dfe7', // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData','hideMenuItems'] // 必填，需要使用的JS接口列表
      });
      weixinJsSdk.error(function(res){
        console.log(res)
          // alert("验证失败"+JSON.stringify(res))// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    }else{
      Toast.fail({
        message: msg,
        duration:'3000'
      });
    }
  })
}
export function getBindStatus(code,openId,cardId,accessToken,redirectPath,getDetail) {
  //如果有openid
  if(openId){
    getOpenId({openid:openId}).then(res=>{
      store.commit('updatedIsLoading', false);
      if(res.wx_code=='0'){
        store.commit('bindStatus', res.bind_status);
        localStorage.setItem("bindStatus", res.bind_status);
        localStorage.setItem("accessToken", res.access_token);
        store.commit('accessToken', res.access_token);
        // localStorage.setItem("openId", res.openid);
        setCookie('openId',res.openid);
        store.commit('openId', res.openid);
        store.commit('cardId', res.card_id);
        localStorage.setItem("cardId", res.card_id);
        accessToken = res.access_token;
        cardId = res.card_id;
        if(res.bind_status !=='1'){
          router.push({ path: '/login?redirect='+redirectPath });
        }else{
          getDetail;
        }
      }else{
        Toast.fail({
          message: res.msg,
          duration:'3000'
        });
      }
    })
  } else{
    if(!code) {
      store.commit('bindStatus', '1');
      localStorage.setItem("bindStatus", '1');
      let uiCode = encodeURIComponent(domainUrl+redirectPath);
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
    }else{//获取code
      getOpenId({percode:code}).then(res=>{
        store.commit('updatedIsLoading', false);
        if(res.wx_code=='0'){
          store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          store.commit('accessToken', res.access_token);
          localStorage.setItem("accessToken", res.access_token);
          store.commit('openId', res.openid);
          // localStorage.setItem("openId", res.openid);
          setCookie('openId',res.openid)
          store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          accessToken = res.access_token;
          cardId = res.card_id;
          if(res.bind_status !=='1'){
            router.push({ path: '/login?redirect=/selfRepayment' });
          }else{
            getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      });
    }
  }
}