import axios from 'axios'
import store from '@/store'
import {getCookie} from './../lib/utils'
import { Toast } from 'vant';
let server = axios.create({
    responseType: 'json'
});
server.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token')||getCookie('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    store.commit('updatedIsLoading', true)
    return config;
}, function (err) {
    return Promise.reject(err);
});

function fetch(url, param = {}) {
    return new Promise((resolve, reject) => {
        server.post(url, param)
            .then(({data}) => {
                let {status,msg}=data;
                store.commit('updatedIsLoading', false);
                // if(status!=='success'){
                //     store.commit('alertModal', {msg});
                // }
                resolve(data);
            }, err => {
                Toast.fail({
                  message: '网络错误',
                  duration:'3000'
                });
                // store.commit('alertModal', {msg:'网络错误'});
                reject(err)
            })
    })
}
function fetchGet(url, param) {
    return new Promise((resolve, reject) => {
        server.get(url, param)
            .then(({data}) => {
                let {status,msg}=data;
                store.commit('updatedIsLoading', false);
                if(status!=='success'){
                    store.commit('alertModal', {msg});
                }
                resolve(data);
            }, err => {
                store.commit('alertModal', {msg:'网络错误'});
                reject(err)
            })
    })
}

// 获取短信验证码
// export const getMobileCode = (param) => {
//     return fetch('/api/auth/sendCode', param);
// };
export const getMobileCode = (param) => {
    return fetch('/wxapi/cardmobile_con', param);
};
// 获取登录信息
// export const doLogin = (param) => {
//     return fetch('/api/auth/login', param);
// };
export const doLogin = (param) => {
    return fetch('/wxapi/cus_binding', param);
};

// 获取注册后信息
export const doRegister = (param) => {
    return fetch('/api/auth/regist', param);
};

// 获取注册后信息
export const getUserAnswers = (param) => {
    return fetch('/api/auth/bindUserAnswers', param);
};

// 获取合同详情
export const getContractDetail = (param) => {
    return fetch('/api/auth/contractDetail', param);
};
// 获取还款计划
export const getPlanInfo = (param) => {
    return fetch('/api/auth/plan', param);
};

// 获取合同列表
export const getContractList = (param) => {
    return fetch('/api/auth/contract', param);
};

// 获取openId
// export const getOpenId = (param) => {
//     return fetch('/api/wx/getOpenId', param);
// };
// export const getOpenId = (param) => {
//     return fetchGet('/wxapi/get_token?percode='+param);
// };
// 退出登录
export const layout = (param) => {
    return fetch('/api/auth/logout', param);
};
// 获取用户信息
export const getUserInfo = () => {
    return fetch('/api/auth/getUserInfo');
};
//提前结清测算信息返显
export const calculationDetail = (param) => {
  return fetch('/wxapi/cus_settle_base', param);
};
//提前结清测算信息测算
export const calculation = (param) => {
  return fetch('/wxapi/cus_settle_calculate', param);
};
//提前结清申请信息返显
export const settleApplicationDetail = (param) => {
  return fetch('/wxapi/do_settle_base', param);
};
//提前结清申请信息提交
export const settleApplication = (param) => {
  return fetch('/wxapi/do_settle', param);
};
//获取图片上传的token
export const getImgToken = (param) => {
  return fetch('/wxapi/imgask', param);
};
//图片上传
// export const upFile = (param) => {
//   return fetch('/oraflfile/upload', param,{headers: { //添加请求头
//     Authorization:'Bearer' +localStorage.getItem('imgToken')
// }});
// };
// export const upFile = (param) => {
//   return fetch('/oraflfile/upload',param);
// };
export const upFile = (param) => {
  return fetch('/oraflfile/uploadAndZip',param);
};
//图片上传展示
export const upFileShow = (param) => {
  return fetch('/wxapi/imgredisplay',param);
};
//图片上传保存
export const upFileSave = (param) => {
  return fetch('/wxapi/imgreceive',param);
};
//对公还款基本信息
export const corporateRepaymentDetail = (param) => {
  return fetch('/wxapi/com_account_base', param);
};
//对公还款提交
export const corporateRepaymentSub = (param) => {
  return fetch('/wxapi/com_account', param);
};
//租金计划rentplanf
export const rentPlanContract = (param) => {
  return fetch('/wxapi/rentplanf', param);
};
//租金计划列表
export const rentPlanList = (param) => {
  return fetch('/wxapi/rentplans', param);
};
//租金计划详情
export const rentPlanDetail = (param) => {
  return fetch('/wxapi/rentplant', param);
};
//变更还款日期
export const modifyRepayment = (param) => {
  return fetch('/wxapi/changedatef', param);
};
//我的申请
export const myApply = (param) => {
  return fetch('/wxapi/myapply', param);
};
//返回变更后计划日期和多还款租息
export const changeDates = (param) => {
  return fetch('/wxapi/changedates', param);
};
//变更还款日期提交
export const modifyRepaymentSub = (param) => {
  return fetch('/wxapi/changedatet', param);
};
//手机号变更
export const modifyInfo = (param) => {
  return fetch('/wxapi/changeinfof', param);
};
//手机号变更提交
export const modifyInfoSub = (param) => {
  return fetch('/wxapi/changeinfos', param);
};
//扣款卡变更
export const modifyCardInfo= (param) => {
  return fetch('/wxapi/changecardf', param);
};
//扣款卡变更提交
export const modifyCardSub = (param) => {
  return fetch('/wxapi/changecards', param);
};
//我的账户信息回显
export const accountInfo = (param) => {
  return fetch('/wxapi/unbinding_read', param);
};
//我的账户解绑
export const accountUnbind = (param) => {
  return fetch('/wxapi/unbinding', param);
};
//获取绑定状态
export const getBindStatus = (param) => {
  return fetch('wxapi/bindstatus', param);
};
//获取所有状态
export const getOpenId = (param) => {
  return fetch('/wxapi/get_token', param);
};
//订单/申请查询
export const orderinquiry = (param) => {
  return fetch('/wxapi/orderinquiry', param);
};
export const orderDetail = (param) => {
  return fetch('/wxapi/orderdetail', param);
};
//合同制作
export const generateContract = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'contract',
    method: 'generateContract',
    ...param
  });
};
//合同盖章
export const compoundContract = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'contract',
    method: 'compoundContract',
    ...param
  });
};
//查看电子合同-回租
export const eleContractQuery = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'contractQuery',
    method: 'getHzContract',
    ...param
  });
};
//查看电子合同-直租
export const eleContractQueryZz = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'contractQuery',
    method: 'getZzContract',
    ...param
  });
};
//合同查询
export const queryContract = (param) => {
  return fetch('/wxapi/coninquiry', param);
};
//合同查询详情
export const condetail = (param) => {
  return fetch('/wxapi/condetail', param);
};
//扣款卡查询合同列表
export const coninquiry = (param) => {
  return fetch('/wxapi/coninquiry', param);
};
//结清查询合同列表
export const settleconlist = (param) => {
  return fetch('/wxapi/settleconlist', param);
};
//扣款卡查询详情
export const cardinquirys = (param) => {
  return fetch('/wxapi/cardinquirys', param);
};
//电子租金计划表
export const rentplansPdf = (param) => {
  return fetchGet('/wxapi/rentplans_pdf?contract_number='+ param);
}; 
//还款详情
export const payinfopage = (param) => {
  return fetch('/wxapi/payinfopage', param);
};
//还款明细
export const payinfodetail = (param) => {
  return fetch('/wxapi/payinfodetail', param);
};
//统一下单接口/oraflapi/apis/dev/test
export const toOrder = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'planPay',
    method: 'toOrder',
    data:{
      ...param
    }
  });
};
//查询支付结果
export const findOrderPayStatus = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'planPay',
    method: 'findOrderPayStatus',
    data:{
      ...param
    }
  });
};
//页面返显时间
export const findPayConfingDetail = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'planPay',
    method: 'findPayConfingDetail',
    data:{
      ...param
    }
  });
};
//还款详情
export const getShare = (param) => {
  return fetch('/wxapi/signshare', param);
};
//获取openid和nickName
export const getNickName = (param) => {
  return fetch('/wxapi/wxnickname', param);
};
//结清资料邮寄-邮寄列表
export const settlemailinfof = (param) => {
  return fetch('/wxapi/settlemailinfof', param);
};
//结清资料邮寄-邮寄详情
export const settlemailinfos = (param) => {
  return fetch('/wxapi/settlemailinfos', param);
};
//公众号查询统计
export const querytype = (param) => {
  return fetch('/wxapi/querytype', param);
};
//公众号-开放未开放状态提示
export const getWxMenuCue = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'wxConfigQuery',
    method: 'getWxMenuCue',
    ...param
  });
};
//前置保证金-自然人合同列表
export const getPersonPayContractList = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'getPersonPayContractList',
    ...param
  });
};
//前置保证金-自然人确认选择合同
export const confirmPersonPayContractList = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'confirmPersonPayContractList',
    ...param
  });
};
//前置保证金-自然人选择支付合同列表
export const getConfirmPersonPayContractList = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'getConfirmPersonPayContractList',
    ...param
  });
};
//前置保证金-自然人和公户线下确认已付款
export const confirmPayInfo = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution', 
    method: 'confirmPayInfo',
    ...param
  });
};
//前置保证金-自然人线上确认支付
export const wxPay = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution', 
    method: 'wxPay',
    ...param
  });
};
//前置保证金-自然人线上确认支付查询支付结果
export const findPayStatus = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution', 
    method: 'findPayStatus',
    ...param
  });
};
//前置保证金-重选合同
export const resetContractInfo = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution', 
    method: 'resetContractInfo',
    ...param
  });
};
//前置保证金-自然人合同详情 个人申请
export const getRequestPersonPayContractInfo = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'getRequestPersonPayContractInfo',
    ...param
  });
};
//前置保证金-公户合同列表
export const getCorpPayContractList = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'getCorpPayContractList',
    ...param
  });
};
//前置保证金-公户确认选择合同
export const confirmCorpPayContractList = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'confirmCorpPayContractList',
    ...param
  });
};
//前置保证金-公户选择支付合同列表
export const getConfirmCorpPayContractList = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'getConfirmCorpPayContractList',
    ...param
  });
};
//前置保证金-公户合同详情 企业申请
export const getRequestCorpPayContractInfo = (param) => {
  return fetch('/oraflapi/apis',{
    appId: 'perCaution',
    method: 'getRequestCorpPayContractInfo',
    ...param
  });
};

