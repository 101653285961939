import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import {getCookie} from './../lib/utils'
export default new Vuex.Store({
    state: {
        token:localStorage.getItem("token")||getCookie("token")||'',
        // accessToken:localStorage.getItem("accessToken")||getCookie("accessToken"),,
        accessToken:localStorage.getItem("accessToken")||'',
        bindStatus:'',
        // openId:getCookie("openId")||'',
        cardId:localStorage.getItem("cardId")||'',
        isLoading: true,
        msg:'',
        openId:localStorage.getItem("openId"),
        openAgree:false,
        modalBtnCancel:false,
        fn:null,
        imgUrlList:[],
        fileList:[],
        backFlag:'',
        backFlagNum:'',
        dataDetailList:{},
        writeData:{},
        submitData:{},//提交之后所存数据
        earlyDataList:[],
        corporateDataList:[],
        modifyDataList:[],
        dataListTotal:[],//返回的所有数据
        dataListTotalCal: [],//返回提前结清测算的所有数据
        mailingListTotal: [],//结清资料邮寄-邮寄列表
        authorData:{},//需要传入transferAuthorization页的划款授权书模板字段
        authorModelData:{},//接口返回的划款授权书模板所需字段,
        authorDetailUrl:'',//pdf地址
        calculationToApply:{},//从结清测算页面传到结清申请页面的字段（合同号、结清日期和结清金额）
        settQueryContDetail:{},//自助还款合同详情数据（需要合同号和合同类型）
        publicData: {},//企业数据
				publicAccessToken: ''
    },
    mutations: {
			settQueryContDetail(state,data){
				state.settQueryContDetail = data;
			},
			calculationToApply(state,data){
				state.calculationToApply = data;
			},
			authorDetailUrl(state,data){
				state.authorDetailUrl = data;
			},
			dataListTotal(state,data){
				state.dataListTotal = data;
			},
			dataListTotalCal(state,data){
				state.dataListTotalCal = data;
            },
            mailingListTotal(state,data){
                state.mailingListTotal = data;
            },
			authorData(state,data){
				state.authorData = data;
			},
			authorModelData(state,data){
				state.authorModelData = data;
			},
        earlyDataList(state,data){
            state.earlyDataList = data;
        },
        corporateDataList(state,data){
            state.corporateDataList = data;
        },
        modifyDataList(state,data){
            state.modifyDataList = data;
        },
        submitData(state,submitData){
            state.submitData = submitData;
        },
        writeData(state,writeData){
            state.writeData = writeData;
        },
        accessToken(state,data){
            state.accessToken = data;
        },
        bindStatus(state,data){
            state.bindStatus = data;
        },
        openId(state,data){
            state.openId = data;
        },
        cardId(state,data){
            state.cardId = data;
        },
        dataDetailList(state,dataDetailList){
            state.dataDetailList = dataDetailList;
        },
        backFlag(state,backFlag){
            state.backFlag = backFlag;
        },
        backFlagNum(state,data){
            state.backFlagNum = data;
        },
        fileList(state,fileList){
            state.fileList = fileList;
        },
        imgUrlList(state,imgUrlList){
            state.imgUrlList = imgUrlList;
        },
        updatedIsLoading(state,loading){
            state.isLoading = loading;
        },
        alertModal(state,payload){
            let {msg,modalBtnCancel,fn}=payload;
            state.msg = msg||'';
            if(modalBtnCancel){
                state.modalBtnCancel = modalBtnCancel||false;
            }else{
                state.modalBtnCancel=false;
            }
            if((typeof fn)==='function'){
                state.fn =fn;
            }else{
                state.fn=null;
            }
        },
        updatedOpenAgree(state,onOff){
            state.openAgree = onOff;
        },
				setPublicData(state,data){
					state.publicData = data
				},
				publicAccessToken(state,data){
					state.publicAccessToken = data
				}
    },
    actions: {

    }
})
