export let routes = [
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Register'),
        meta: {
            title: '注册账号'
        },
    },
    {
        path: '/questionlist',
        name: 'questionlist',
        component: () => import(/* webpackChunkName: "questionlist" */ '@/views/QuestionList'),
        meta: {
            title: '问卷调查'
        },
    },
    {
        path: '/registersuccess',
        name: 'registersuccess',
        component: () => import(/* webpackChunkName: "registersuccess" */ '@/views/RegisterSuccess'),
        meta: {
            title: '问卷结果'
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
        meta: {
            title: '绑定账号'
        },
    },
    // {path: '/', redirect: {name: 'contractdetail'}},
    {
        path: '/contractlist',
        name: 'contractlist',
        component: () => import(/* webpackChunkName: "contractlist" */ '@/views/ContractList'),
        meta: {
            requireAuth: true,
            title: '选择合同'
        }
    },
    {
        path: '/contractdetail',
        name: 'contractdetail',
        component: () => import(/* webpackChunkName: "" */ '@/views/ContractDetail'),
        meta: {
            requireAuth: true,
            title: '合同信息'
        }
    },
   /* {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "about" *!/ './../views/About.vue'),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/hello',
        name: 'hello',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "hello" *!/ './../views/HelloWorld.vue'),
        meta: {
            title: '你好'
        }
    },*/
    {path: '*', redirect: {name: 'login'}},
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
        meta: {
            requireAuth: true,
            title: '我的账号'
        }
    },
    {
        path: '/rentPlanCtr',
        name: 'rentPlanCtr',
        component: () => import('@/views/rentPlanCtr'),
        meta: {
            requireAuth: true,
            title: '选择合同'
        }
    },
    {
        path: '/rentPlanPayDetail',
        name: 'rentPlanPayDetail',
        component: () => import('@/views/rentPlanCtr/rentPlanDetail'),
        meta: {
            requireAuth: true,
            title: '租金计划'
        }
    },
    {
        path: '/rentalSchedule',
        name: 'rentalSchedule',
        component: () => import('@/views/rentPlanCtr/rentalSchedule'),
        meta: {
            requireAuth: true,
            title: '电子租金计划表'
        }
    },
    {
        path: '/rentPlanPay',
        name: 'rentPlanPay',
        component: () => import('@/views/rentPlanCtr/rentPlanPay'),
        meta: {
            requireAuth: true,
            title: '租金计划还款'
        }
    },
    // {
    //     path: '/rentPlanUnpay',
    //     name: 'rentPlanUnpay',
    //     component: () => import('@/views/rentPlanCtr/rentPlanUnpay'),
    //     meta: {
    //         requireAuth: true,
    //         title: '租金计划待还款'
    //     }
    // },
    {
        path: '/earlySettlement',
        name: 'earlySettlement',
        component: () => import(/* webpackChunkName: "contractdetail" */ '@/views/earlySettlement'),
        meta: {
            requireAuth: true,
            title: '提前结清'
        }
    },
    {
        path: '/earlySettlementDetail',
        name: 'earlySettlementDetail',
        component: () => import('@/views/earlySettlement/earlySettlementDetail'),
        meta: {
            requireAuth: true,
            title: '提前结清'
        }
    },
    {
        path: '/earlySettlementCalculation',
        name: 'earlySettlementCalculation',
        component: () => import('@/views/earlySettlement/earlySettlementCalculation'),
        meta: {
            requireAuth: true,
            title: '结清测算'
        }
    },
    {
        path: '/earlySettlementApplication',
        name: 'earlySettlementApplication',
        component: () => import('@/views/earlySettlement/earlySettlementApplication'),
        meta: {
            requireAuth: true,
            title: '结清申请'
        }
    },
    {
        path: '/upFile',
        name: 'upFile',
        component: () => import('@/views/upFile'),
        meta: {
            requireAuth: true,
            title: '上传附件'
        }
    },
    {
        path: '/upFileDetail',
        name: 'upFileDetail',
        component: () => import('@/views/upFile/upFileDetail'),
        meta: {
            requireAuth: true,
            title: '上传附件'
        }
    },
    {
        path: '/corporateRepayment',
        name: 'corporateRepayment',
        component: () => import('@/views/corporateRepayment'),
        meta: {
            requireAuth: true,
            title: '对公还款'
        }
    },
    {
        path: '/corporateRepaymentDetail',
        name: 'corporateRepaymentDetail',
        component: () => import('@/views/corporateRepayment/corporateRepaymentDetail'),
        meta: {
            requireAuth: true,
            title: '对公还款'
        }
    },
    {
        path: '/application',
        name: 'application',
        component: () => import('@/views/application'),
        meta: {
            requireAuth: true,
            title: '我的申请'
        }
    },
    {
        path: '/afterRentModify',
        name: 'afterRentModify',
        component: () => import('@/views/afterRentModify'),
        meta: {
            requireAuth: true,
            title: '租后变更'
        }
    },
    {
        path: '/modifyRepayment',
        name: 'modifyRepayment',
        component: () => import('@/views/afterRentModify/modifyRepayment'),
        meta: {
            requireAuth: true,
            title: '变更还款日'
        }
    },
    {
        path: '/modifyRepaymentDetail',
        name: 'modifyRepaymentDetail',
        component: () => import('@/views/afterRentModify/modifyRepayment/modifyRepaymentDetail'),
        meta: {
            requireAuth: true,
            title: '变更还款日'
        }
    },
    {
      path: '/modifyInfo',
      name: 'modifyInfo',
      component: () => import('@/views/afterRentModify/modifyInfo'),
      meta: {
          requireAuth: true,
          title: '手机号变更'
      }
    },
    {
        path: '/modifyInfoDetail',
        name: 'modifyInfoDetail',
        component: () => import('@/views/afterRentModify/modifyInfo/modifyInfoDetail'),
        meta: {
            requireAuth: true,
            title: '手机号变更'
        }
    },
    {
        path: '/cardQuery',
        name: 'cardQuery',
        component: () => import('@/views/cardQuery'),
        meta: {
            requireAuth: true,
            title: '扣款卡合同查询'
        }
    },
    {
        path: '/cardQueryDetail',
        name: 'cardQueryDetail',
        component: () => import('@/views/cardQuery/cardQueryDetail'),
        meta: {
            requireAuth: true,
            title: '扣款卡查询'
        }
    },
    {
      path: '/modifyDebitCard',
      name: 'modifyDebitCard',
      component: () => import('@/views/afterRentModify/modifyDebitCard'),
      meta: {
          requireAuth: true,
          title: '扣款卡变更'
      }
    },
    {
        path: '/modifyDebitCardDetail',
        name: 'modifyDebitCardDetail',
        component: () => import('@/views/afterRentModify/modifyDebitCard/modifyDebitCardDetail'),
        meta: {
            requireAuth: true,
            title: '扣款卡变更'
        }
    },
    {
        path: '/transferAuthorization',
        name: 'transferAuthorization',
        component: () => import('@/views/afterRentModify/modifyDebitCard/transferAuthorization'),
        meta: {
            requireAuth: true,
            title: '划款授权书'
        }
    },
    {
        path: '/transferAuthorizationDetail',
        name: 'transferAuthorizationDetail',
        component: () => import('@/views/afterRentModify/modifyDebitCard/modifyDebitCardDetail/transferAuthorizationDetail'),
        meta: {
            requireAuth: true,
            title: '划款授权书'
        }
    },
    {
        path: '/transferSign',
        name: 'transferSign',
        component: () => import('@/views/afterRentModify/modifyDebitCard/transferSign'),
        meta: {
            requireAuth: true,
            title: '客户签名'
        }
    },
    {
        path: '/applyQuery',
        name: 'applyQuery',
        component: () => import('@/views/applyQuery'),
        meta: {
            requireAuth: true,
            title: '申请查询'
        }
    },
    {
        path: '/applyQueryDetail',
        name: 'applyQueryDetail',
        component: () => import('@/views/applyQuery/applyQueryDetail'),
        meta: {
            requireAuth: true,
            title: '申请详情'
        }
    },
    {
        path: '/contractQuery',
        name: 'contractQuery',
        component: () => import('@/views/contractQuery'),
        meta: {
            requireAuth: true,
            title: '合同查询'
        }
    },
		{
			path: '/settlementQuery',
			name: 'settlementQuery',
			component: () => import('@/views/settlementQuery'),
			meta: {
					requireAuth: true,
					title: '结清查询'
			}
		},
		{
			path: '/settlementQueryDisplay',
			name: 'settlementQueryDisplay',
			component: () => import('@/views/settlementQuery/settlementQueryDisplay'),
			meta: {
				title: '结清证明'
			}
    },
    {
        path: '/contractQueryDetail',
        name: 'contractQueryDetail',
        component: () => import('@/views/contractQuery/contractQueryDetail'),
        meta: {
            requireAuth: true,
            title: '合同详情'
        }
    },
		{
			path: '/pdfDisplay',
			name: 'pdfDisplay',
			component: () => import('@/views/contractQuery/pdfDisplay'),
			meta: {
				title: '电子合同'
			}
    },
    {
        path: '/settleInfoMail',
        name: 'settleInfoMail',
        component: () => import('@/views/settleInfoMail'),
        meta: {
                requireAuth: true,
                title: '资料邮寄查询'
        }
    },{
        path: '/settleInfoMail/mailingList',
        name: 'mailingList',
        component: () => import('@/views/settleInfoMail/mailingList'),
        meta: {
                requireAuth: true,
                title: '邮寄列表'
        }
    },{
        path: '/settleInfoMail/mailingDetail',
        name: 'mailingDetail',
        component: () => import('@/views/settleInfoMail/mailingDetail'),
        meta: {
                requireAuth: true,
                title: '邮寄详情'
        }
    },
		{
			path: '/selfRepayment',
			name: 'selfRepayment',
			component: () => import('@/views/selfRepayment'),
			meta: {
					requireAuth: true,
					title: '自助还款'
			}
		},
		{
			path: '/selfRepaymentDetail',
			name: 'selfRepaymentDetail',
			component: () => import('@/views/selfRepayment/selfRepaymentDetail'),
			meta: {
					requireAuth: true,
					title: '还款信息'
			}
		},
		{
			path: '/self-repayment-success',
			name: 'selfRepaymentSuccess',
			component: () => import('@/views/selfRepayment/selfRepaymentSuccess'),
			meta: {
					requireAuth: true,
					title: '支付成功'
			}
		},
    {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/personalCenter'),
        meta: {
            requireAuth: true,
            title: '自然人客户'
        }
    },
    {
        path: '/trainOperation',
        name: 'trainOperation',
        component: () => import('@/views/trainOperation/trainOperation'),
        meta: {
            title: '车务服务'
        }
    },
    {
        path: '/trafficCharge',
        name: 'trafficCharge',
        component: () => import('@/views/trainOperation/trafficCharge'),
        meta: {
            title: '委托车务'
        }
    },
    {
        path: '/serviceDescription',
        name: 'serviceDescription',
        component: () => import('@/views/trainOperation/serviceDescription'),
        meta: {
            title: '服务介绍'
        }
    },
    {
        path: '/customerConsultation',
        name: 'customerConsultation',
        component: () => import('@/views/trainOperation/customerConsultation'),
        meta: {
            title: '客服咨询'
        }
    },
    {
        path: '/pastServices',
        name: 'pastServices',
        component: () => import('@/views/trainOperation/pastServices'),
        meta: {
            title: '过往服务'
        }
    },
    {
        path: '/pastServicesDetail',
        name: 'pastServicesDetail',
        component: () => import('@/views/trainOperation/pastServicesDetail'),
        meta: {
            title: '过往服务'
        }
    },
    {
        path: '/servicePayment',
        name: 'servicePayment',
        component: () => import('@/views/trainOperation/servicePayment'),
        meta: {
            title: '服务缴费'
        }
    },

    {
		path: '/privacy',
		name: 'privacy',
		component: () =>
				import ('@/views/privacy'),
		meta: {
				title: '隐私政策'
		},
	},
    {
		path: '/uerAgreement',
		name: 'uerAgreement',
		component: () =>
				import ('@/views/uerAgreement'),
		meta: {
				title: '用户服务协议'
		},
	},
    {
		path: '/bindAgreement',
		name: 'bindAgreement',
		component: () =>
				import ('@/views/bindAgreement'),
		meta: {
				title: '绑定协议'
		},
	},  
	{
        path: '/cellCenter',
        name: 'cellCenter',
        component: () => import('@/views/cellCenter/index'),
        meta: {
            title: '呼叫中心'
        }
    },	
		// {		// 	path: '/ceshi',
		// 	name: 'ceshi',
		// 	component: () => import('@/views/ceshi'),
		// 	meta: {
		// 			title: '测试'
		// 	}
		// },
		// {
		// 	path: '/ceshi/share',
		// 	name: 'share',
		// 	component: () => import('@/views/ceshi/share'),
		// 	meta: {
		// 			title: '测试分享'
		// 	}
		// },
  //公户
  {
    path: '/enterprise/contractList',
    name: 'enterpriseContractList',
    component: () => import('@/views/enterprise/contractList/index'),
    meta: {
      title: '合同列表-企业'
    }
  },
  {
    path: '/enterprise/contractList/electronicRent',
    name: 'enterpriseElectronicRent',
    component: () => import('@/views/enterprise/contractList/electronicRent/index'),
    meta: {
      title: '电子租金计划表'
    }
  },
  {
    path: '/enterprise/contractList/electronicRent/pdfDisplay',
    name: 'enterpriseElectronicRentpdf',
    component: () => import('@/views/enterprise/contractList/electronicRent/pdfDisplay'),
    meta: {
      title: '电子租金计划表pdf'
    }
  },
      {
    path: '/enterprise/enterpriseBind',
    name: 'enterpriseEnterpriseBind',
    component: () => import('@/views/enterprise/enterpriseBind/index'),
    meta: {
      title: '企业客户绑定'
    }
  },
      {
    path: '/enterprise/enterprisePersonalCenter',
    name: 'enterpriseEnterprisePersonalCenter',
    component: () => import('@/views/enterprise/enterprisePersonalCenter/index'),
    meta: {
      title: '企业客户'
    }
  },
  {
    path: '/advanceDeposit',
    name: 'advanceDeposit',
    component: () => import('@/views/advanceDeposit/index'),
    meta: {
        title: '选择付款合同'
    }
  },
  {
    path: '/advanceDeposit/payment',
    name: 'payment',
    component: () => import('@/views/advanceDeposit/payment/index'),
    meta: {
        title: ''
    }
  },
  {
    path: '/advanceDeposit/paymentDetail',
    name: 'payment',
    component: () => import('@/views/advanceDeposit/paymentDetail/index'),
    meta: {
        title: '前置保证金付款申请'
    }
  },
  {
    path: '/enterprise/enterpriseAdvanceDeposit',
    name: 'publicAdvanceDeposit',
    component: () => import('@/views/enterprise/advanceDeposit/index'),
    meta: {
        title: '选择付款合同-企业'
    }
  },
  {
    path: '/enterprise/enterpriseAdvanceDeposit/payment',
    name: 'publicPayment',
    component: () => import('@/views/enterprise/advanceDeposit/payment/index'),
    meta: {
        title: ''
    }
  },
  {
    path: '/enterprise/enterpriseAdvanceDeposit/paymentDetail',
    name: 'publicPaymentDetail',
    component: () => import('@/views/enterprise/advanceDeposit/paymentDetail/index'),
    meta: {
        title: '前置保证金付款申请'
    }
  },
  {
    path: '/enterprise/enterpriseApplication',
    name: 'enterpriseApplication',
    component: () => import('@/views/enterprise/application/index'),
    meta: {
      // requireAuth: true,
      title: '我的申请'
    }
},
        {
			path: '/enterprise/share',
			name: 'enterpriseShare',
			component: () => import('@/views/enterprise/share/index'),
			meta: {
				title: '分享数据验证'
			}
		},
];