import Vue from 'vue'
import Router from 'vue-router'
import {routes} from './routes'
import {isLogin} from '@/lib/utils'
// import {getOpenId} from '@/server'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
// var openId = localStorage.getItem("openId");
// var bindStatus='';
let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...routes
    ]
});
router.beforeEach((to, from, next) => {
    document.title =to.meta.title?to.meta.title:'信息查询';
    // if(this.openId){
    //   getOpenId({openid:openId}).then(res=>{
    //     this.$store.commit('bindStatus', res.bind_status);
    //     localStorage.setItem("bindStatus", res.bind_status);
    //     bindStatus = res.bind_status;
    //   })
    // } else{

    // }
    if (to.meta.requireAuth===true) {
        if (isLogin()) {
           next()
        } else {
            let {code}=to.query;
            console.log(to.meta.requireAuth)
            next(`/login?redirect=${to.path}`)
            // next({
            //     path: '/login',
            //     query: {code: code}
            // })
        }
    } else {
        next()
        // console.log(to.meta.requireAuth)
    }
});
export default router;
