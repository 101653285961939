<template>
    <div id="app">
        <Modal :msg="$store.state.msg"/>
        <transition mode="out-in">
            <router-view class="center"/>
        </transition>
    </div>
</template>


<script>
    import Modal from './components/Modal'
    export default {
        name: 'App',
        components: {
            Modal
        },
        created() {
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        
        window.addEventListener('unload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state));
        });
        // 在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
            Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store')))
        );
        }
    }
    }
    
</script>

<style lang="less">
.van-dialog__message{
  font-size: 15px !important;
  line-height: 29px !important;
}
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

    }

    #nav {
        padding: 30px;
        a {
            font-weight: bold;
            color: #2c3e50;
            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
    .v-enter{
        opacity: 0;
    }
    .v-enter-to{
        opacity: 1;
    }
    .v-enter-active{
        transition: .5s;
    }
    .v-leave{
        opacity:1;
    }
    .v-leave-to{
        opacity: 0;
    }
    .v-leave-active{
        transition: .5s;
    }
    .van-cell{
    font-size: 14px !important;
}
</style>

